/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const socketStateInitialValue = {
  isConnected: false,
};

const socketSlice = createSlice({
  name: 'socket',
  initialState: socketStateInitialValue,
  reducers: {
    setIsConnected: (state, { payload }: PayloadAction<boolean>) => {
      console.log('set is connected', payload);
      state.isConnected = payload;
    },
  },
});

export const { setIsConnected } = socketSlice.actions;

export default socketSlice.reducer;
