/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TProgressItem = {
  downloadCode: string;
  backUrl: string;
  payload: any;
  isFinished?: boolean;
};

type TDownloadProgressState = {
  progressList: TProgressItem[];
  isIntervalRunning: boolean;
  display: boolean;
};

const INITIAL_STATE: TDownloadProgressState = {
  progressList: [],
  isIntervalRunning: false,
  display: true,
};

const downloadProgressSlice = createSlice({
  name: 'downloadProgress',
  initialState: INITIAL_STATE,
  reducers: {
    addDownloadProgress: (state, { payload }: PayloadAction<TProgressItem>) => {
      state.progressList.push(payload);
      state.isIntervalRunning = false;
      state.display = true;
    },
    removeDownloadProgress: (state, { payload }: PayloadAction<string>) => {
      state.progressList = state.progressList.filter((item) => item.downloadCode !== payload);
      state.isIntervalRunning = false;
    },
    updateDownloadProgress: (state, { payload }: PayloadAction<Partial<TProgressItem>>) => {
      state.progressList = state.progressList.map((item) =>
        item.downloadCode === payload.downloadCode ? { ...item, ...payload } : item,
      );
    },
    setIsIntervalRunning: (state, { payload }: PayloadAction<boolean>) => {
      state.isIntervalRunning = payload;
    },
    updateDisplay: (state, { payload }: PayloadAction<boolean>) => {
      state.display = payload;
    },
  },
});

export const {
  addDownloadProgress,
  removeDownloadProgress,
  updateDownloadProgress,
  setIsIntervalRunning,
  updateDisplay,
} = downloadProgressSlice.actions;

export default downloadProgressSlice.reducer;
